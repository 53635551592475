import React, {useState} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import SDClimographToolJson from "../content/sd_climograph_tool.json"
import more from "highcharts/highcharts-more";  // For highchart arearange type
import axios from 'axios'

if (typeof Highcharts === "object") {
  more(Highcharts);
}

const configChart = ({ data }) => {
    return {
        chart: {
            type: 'area',
            zoomType: 'x',
            backgroundColor: 'transparent'
          },
          credits: {
            text: 'Data Source: ACIS',
            href: 'http://www.rcc-acis.org'
          },
          yAxis: [{
            labels: {
              align: 'right',
              x: -3
            },
            title: {
              text: 'Temperature (°F)'
            },
            height: '45%',
            lineWidth: 2
          }, {
            labels: {
              align: 'right',
              x: -3
            },
            title: {
              text: "Precipitation (in)"
            },
            top: '55%',
            height: '45%',
            offset: 0,
            lineWidth: 2
          }],
          xAxis: {
            type: 'datetime'
          },
          rangeSelector: {
            selected: 2
          },
          title: {
            text: 'Station: ' + data.name + ', ' + data.state
          },
          tooltip: {
            crosshairs: true,
            shared: true
          },
          series: [{
            name: 'Temperature Normals (Max & Min)',
            type: 'arearange',
            fillOpacity: 0.45,
            color: '#FDBB84',
            data: data.temp_nm,
            yAxis: 0,
            tooltip: {
              valueSuffix: '°F'
            }
          }, {
            name: 'Avg Temperature',
            type: 'line',
            color: '#C994C7',
            marker: {
              enabled: false
            },
            data: data.temp_avg,
            yAxis: 0,
            tooltip: {
              valueSuffix: '°F'
            }
          }, {
            name: 'Avg Normals',
            type: 'line',
            color: '#FC8D59',
            marker: {
              enabled: false
            },
            data: data.temp_nm_avg,
            yAxis: 0,
            tooltip: {
              valueSuffix: '°F'
            }
          }, {
            name: 'Daily Temperatures (Max & Min)',
            type: 'arearange',
            color: '#E34A33',
            fillOpacity: 0.25,
            data: data.temp,
            yAxis: 0,
            tooltip: {
              valueSuffix: '°F'
            }
          }, {
            name: 'Cumulative Prcp',
            type: 'area',
            color: '#6BAED6',
            fillOpacity: 0.25,
            marker: {
              enabled: false
            },
            data: data.pcpn,
            yAxis: 1,
            tooltip: {
              valueSuffix: 'in'
            }
          }, {
            name: 'Cumulative Prcp Normals',
            type: 'area',
            color: '#2171B5',
            fillOpacity: 0.25,
            marker: {
              enabled: false
            },
            data: data.pcpn_nm,
            yAxis: 1,
            tooltip: {
              valueSuffix: 'in'
            }
          }, {
            name: 'Historical Max Prcp (' + data.year_pcpn_etm[0] + ')',
            type: 'area',
            color: '#2171B5',
            fillOpacity: 0.25,
            marker: {
              enabled: false
            },
            data: data.pcpn_max,
            yAxis: 1,
            tooltip: {
              valueSuffix: 'in'
            }
          }, {
            name: 'Historical Min Prcp (' + data.year_pcpn_etm[1] + ')',
            type: 'area',
            color: '#2171B5',
            fillOpacity: 0.25,
            marker: {
              enabled: false
            },
            data: data.pcpn_min,
            yAxis: 1,
            tooltip: {
              valueSuffix: 'in'
            }
        }],
        legend: {
            enabled: false
        }
    }
}

export default function SRCCChart() {
    const [chartData, setChartData] = useState({
        data: SDClimographToolJson,
        fetched: false
    })

    const params = {
        sid: "23188",
        year: "2024",
        missdays: "30",
    }
    // Fetch data
    if(!chartData.fetched) {
        // axios.get(`http://localhost:8080/climate_data_portal/getClimgraphTool/`, {
        // https://www.srcc.tamu.edu/climate_data_viewer/getClimgraphTool/?sid=23188&year=2024&missdays=30
        axios.get(`https://www.srcc.tamu.edu/climate_data_viewer/getClimgraphTool/`, {
            params: params
        })
        .then(res => {
            // Update chart params
            // console.log(res.data);
            setChartData({
                data: res.data,
                fetched: true,
            })
        })
        .catch(error => {
            console.error('There was an error!', error);
        })
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={configChart(chartData)}
        />
    )
}
